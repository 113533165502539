(function (root, factory) {
    if ( typeof define === 'function' && define.amd ) {
        define([], factory(root));
    } else if ( typeof exports === 'object' ) {
        module.exports = factory(root);
    } else {
        root.stickyFooter = factory(root);
    }
})(typeof global !== 'undefined' ? global : this.window || this.global, function (root) {

    'use strict';

    //
    // Variables
    //

    var stickyFooter = {}; // Object for public APIs
    var supports = 'querySelector' in document && 'addEventListener' in root; // Feature test
    var settings, wrap, footer, eventTimeout;

    // Default settings
    var defaults = {
        selectorWrap: '[data-sticky-wrap]',
        selectorFooter: '[data-sticky-footer]',
        callback: function () {}
    };


    //
    // Methods
    //

    /**
     * Merge two or more objects. Returns a new object.
     * @private
     * @param {Boolean}  deep     If true, do a deep (or recursive) merge [optional]
     * @param {Object}   objects  The objects to merge together
     * @returns {Object}          Merged values of defaults and options
     */
    var extend = function () {

        // Variables
        var extended = {};
        var deep = false;
        var i = 0;
        var length = arguments.length;

        // Check if a deep merge
        if ( Object.prototype.toString.call( arguments[0] ) === '[object Boolean]' ) {
            deep = arguments[0];
            i++;
        }

        // Merge the object into the extended object
        var merge = function (obj) {
            for ( var prop in obj ) {
                if ( Object.prototype.hasOwnProperty.call( obj, prop ) ) {
                    // If deep merge and property is an object, merge properties
                    if ( deep && Object.prototype.toString.call(obj[prop]) === '[object Object]' ) {
                        extended[prop] = extend( true, extended[prop], obj[prop] );
                    } else {
                        extended[prop] = obj[prop];
                    }
                }
            }
        };

        // Loop through each object and conduct a merge
        for ( ; i < length; i++ ) {
            var obj = arguments[i];
            merge(obj);
        }

        return extended;

    };

    /**
     * Get height of the viewport
     * @private
     * @return {Number} Height of the viewport in pixels
     */
    var getViewportHeight = function () {
        return Math.max( document.documentElement.clientHeight, window.innerHeight || 0 );
    };

    /**
     * Set page wrapper height to fill viewport (minus footer height)
     * @private
     * @param {Element} wrap Page wrapper
     * @param {Element} footer Page footer
     * @param {Object} settings
     */
    var setWrapHeight = function ( wrap, footer, settings ) {
        wrap.style.minHeight = ( getViewportHeight() - footer.offsetHeight ) + 'px';
        settings.callback(); // Run callback
    };

    /**
     * Destroy the current initialization.
     * @public
     */
    stickyFooter.destroy = function () {

        if ( !settings ) return;

        // Unset styles
        document.documentElement.style.minHeight = '';
        document.body.style.minHeight = '';
        wrap.style.minHeight = '';
        window.removeEventListener( 'resize', eventThrottler, false );

        // Reset variables
        settings = null;
        wrap = null;
        footer = null;
        eventTimeout = null;

    };

    /**
     * On window scroll and resize, only run events at a rate of 15fps for better performance
     * @private
     * @param  {Function} eventTimeout Timeout function
     * @param  {NodeList} wrap The content wrapper for the page
     * @param  {NodeList} footer The footer for the page
     * @param  {Object} settings
     */
    var eventThrottler = function () {
        if ( !eventTimeout ) {
            eventTimeout = setTimeout(function() {
                eventTimeout = null;
                setWrapHeight( wrap, footer, settings );
            }, 66);
        }
    };

    /**
     * Initialize Plugin
     * @public
     * @param {Object} options User settings
     */
    stickyFooter.init = function ( options ) {

        // feature test
        if ( !supports ) return;

        // Destroy any existing initializations
        stickyFooter.destroy();

        // Selectors and variables
        settings = extend( defaults, options || {} ); // Merge user options with defaults
        wrap = document.querySelector( settings.selectorWrap );
        footer = document.querySelector( settings.selectorFooter );

        // Sanity check
        if ( !wrap || !footer ) return;

        // Stick footer
        document.documentElement.style.minHeight = '100%';
        document.body.style.minHeight = '100%';
        setWrapHeight( wrap, footer, settings );
        window.addEventListener( 'resize', eventThrottler, false); // Run Sticky Footer on window resize

    };


    //
    // Public APIs
    //

    return stickyFooter;

});